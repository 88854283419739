import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { slug } from '@magalu/mixer-utils';
import { BenefitText, BenefitPriceText, IconCheck, IconPrize } from './ServiceInfo.styles';
var Benefits = function Benefits(_ref) {
  var benefit = _ref.benefit;
  var benefitLength = benefit == null ? void 0 : benefit.length;
  var formatDescriptionAndIcon = function formatDescriptionAndIcon(description) {
    var match = description == null ? void 0 : description.match(/\[(.*?)\]/);
    var iconName = match ? match[1] : 'Done';
    var onlyDescription = description == null ? void 0 : description.replace(/\[.*?\]/, '').trim();
    return {
      iconName: iconName,
      onlyDescription: onlyDescription
    };
  };
  return React.createElement(React.Fragment, null, benefit == null ? void 0 : benefit.map(function (_ref2, index) {
    var description = _ref2.description;
    var _formatDescriptionAnd = formatDescriptionAndIcon(description),
      iconName = _formatDescriptionAnd.iconName,
      onlyDescription = _formatDescriptionAnd.onlyDescription;
    formatDescriptionAndIcon(description);
    var isLastItem = index + 1 < benefitLength;
    return isLastItem ? React.createElement(BenefitText, {
      key: slug(onlyDescription + index),
      "data-testid": "benefit-container",
      pr: 2
    }, React.createElement(IconCheck, {
      name: iconName,
      "data-testid": "benefit-icon"
    }), onlyDescription) : React.createElement(BenefitPriceText, {
      key: slug(onlyDescription + index),
      "data-testid": "benefit-container",
      pr: 3
    }, React.createElement(IconPrize, {
      name: iconName,
      "data-testid": "benefit-icon"
    }), onlyDescription);
  }));
};
Benefits.defaultProps = {
  benefit: [{
    description: ''
  }]
};
process.env.NODE_ENV !== "production" ? Benefits.propTypes = {
  benefit: arrayOf(shape({
    description: string
  }))
} : void 0;
export default Benefits;