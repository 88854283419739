import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect } from 'react';
import { arrayOf, bool, number, objectOf, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import { usePublisher } from '@magalu/mixer-publisher';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ConfirmDialog from '../ConfirmDialog';
import useFinancialServicesNew from './useFinancialServicesNew';
import ActionButtons from './ActionButtons';
import ServiceGroup from './ServiceGroup';
import { ServicesGrid, ContainerIcon, ActionButtonsWrapper } from './FinancialServicesSeparateSale.styles';
import ProductCard from './ProductCard';
var CHANGE_EVENT = 'financial-services-separate-sale:change';
var VIEW_EVENT = 'financial-services-separate-sale:view';
var INSURANCE_SERVICE_PATH = '/seguro/servicos/s/se/segr/';
var FinancialServicesSeparateSale = function FinancialServicesSeparateSale(props) {
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _useFinancialServices = useFinancialServicesNew(props),
    actionButtonProps = _useFinancialServices.actionButtonProps,
    isEligible = _useFinancialServices.isEligible,
    services = _useFinancialServices.services,
    productInfo = _useFinancialServices.productInfo;
  var modalNotEligible = props["static"].modalNotEligible,
    structure = props.structure;
  var cookies = structure.cookies,
    device = structure.device;
  var customerId = cookies.customerId,
    partnerId = cookies.partnerId;
  var icon = modalNotEligible == null ? void 0 : modalNotEligible.icon;
  useEffect(function () {
    publish(VIEW_EVENT, {
      device: device,
      partnerId: partnerId,
      userId: customerId,
      userIsLogged: true,
      userLocation: _extends({}, cookies == null ? void 0 : cookies.location, {
        zipCode: cookies == null ? void 0 : cookies.zipCode
      })
    });
  }, []);
  useEffect(function () {
    if (services == null ? void 0 : services.length) {
      services.forEach(function (service) {
        var _service$period$optio, _service$period$optio2;
        publish(CHANGE_EVENT, {
          validity: (_service$period$optio = service.period.options) == null ? void 0 : (_service$period$optio2 = _service$period$optio.find(function (period) {
            return period.value === service.period.value;
          })) == null ? void 0 : _service$period$optio2.validity
        });
      });
    }
  }, [services]);
  var handleRedirectOnClick = function handleRedirectOnClick() {
    return routePush({
      path: INSURANCE_SERVICE_PATH,
      spa: true
    });
  };
  var renderHeaderIcon = function renderHeaderIcon() {
    return React.createElement(ContainerIcon, null, React.createElement(Icon, {
      name: icon == null ? void 0 : icon.name,
      height: icon == null ? void 0 : icon.height,
      width: icon == null ? void 0 : icon.width,
      color: icon == null ? void 0 : icon.color
    }));
  };
  var renderContent = function renderContent() {
    return React.createElement(ConfirmDialog, {
      open: true,
      onClose: handleRedirectOnClick,
      title: modalNotEligible == null ? void 0 : modalNotEligible.title,
      infoText: modalNotEligible == null ? void 0 : modalNotEligible.describe,
      confirmText: modalNotEligible == null ? void 0 : modalNotEligible.confirmText,
      confirmAction: handleRedirectOnClick,
      headerComponent: renderHeaderIcon()
    });
  };
  return React.createElement(Grid, {
    "data-testid": "services-container-desktop"
  }, isEligible ? React.createElement(React.Fragment, null, React.createElement(ProductCard, {
    productInfo: productInfo
  }), React.createElement(ServicesGrid, {
    "data-testid": "services-grid",
    nCards: services.length
  }, services.map(function (service) {
    return React.createElement(ServiceGroup, {
      key: "service-group-".concat(service.key),
      service: service
    });
  })), React.createElement(ActionButtonsWrapper, null, React.createElement(ActionButtons, actionButtonProps))) : renderContent());
};
FinancialServicesSeparateSale.defaultProps = {
  data: {
    eligibleProduct: {
      installment: number
    },
    product: {
      financialServices: [],
      installment: {}
    },
    serviceContent: {
      financialServicesContent: [],
      footerContent: []
    }
  },
  "static": {
    extraInfo: {},
    modalNotEligible: {
      confirmText: 'Ver outros seguros',
      describe: 'No momento, o serviço está indisponível devido as regras para contratação.',
      icon: {
        color: '#ffce00',
        height: '149px',
        name: 'AlertOperation',
        width: '202px'
      },
      insuranceServicePath: '/seguro/servicos/s/se/segr/',
      title: 'Serviço selecionado indisponível'
    },
    overrideDeclineMessages: {}
  }
};
process.env.NODE_ENV !== "production" ? FinancialServicesSeparateSale.propTypes = {
  data: shape({
    eligibleProduct: shape({
      eligible: bool,
      product: shape({
        customerUUID: string,
        orderId: string,
        orderUUID: string,
        productBrand: string,
        productDescription: string,
        productImageUrl: string,
        productSku: string,
        sellerId: string,
        serviceSlug: string,
        subOrderId: string,
        subOrderUUID: string
      }),
      services: arrayOf(shape({
        description: string,
        id: string,
        integrationCode: string,
        offers: arrayOf(shape({
          campaignId: string,
          description: string,
          id: number,
          integrationCode: string,
          price: number,
          recommended: bool,
          treeId: string,
          validity: number,
          validityType: string,
          wageCompensation: shape({
            amount: string,
            percentage: string
          })
        })),
        supplier: shape({
          cnpj: string,
          name: string,
          trandingName: string
        })
      }))
    }),
    product: shape({
      financialServices: arrayOf(shape({
        description: string,
        id: string
      })),
      installment: shape({
        quantity: number
      })
    }),
    serviceContent: shape({
      financialServicesContent: arrayOf(shape({
        title: string
      })),
      footerContent: arrayOf(shape({
        title: string
      }))
    })
  }),
  "static": shape({
    extraInfo: shape({
      label: string,
      labelBold: string,
      title: string
    }),
    modalNotEligible: shape({
      confirmText: string,
      describe: string,
      icon: shape({
        color: string,
        height: string,
        name: string,
        width: string
      }),
      insuranceServicePath: string,
      title: string
    }),
    overrideDeclineMessages: objectOf(shape({
      description: string
    }))
  }),
  structure: shape({
    cookies: shape({
      customerId: string
    }).isRequired
  }).isRequired
} : void 0;
FinancialServicesSeparateSale.ssr = true;
FinancialServicesSeparateSale.dataSource = {
  serviceContent: {
    query: 'ServiceContentQuery'
  }
};
export default withLayoutProps(FinancialServicesSeparateSale);