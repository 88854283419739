import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import RawButton from '@magalu/stereo-ui/atoms/Button';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var Button = styled(RawButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 12px;\n  font-size: 16px;\n  height: 48px;\n  width: 100%;\n  margin: 0 5px;\n  ", " {\n    width: 320px;\n  }\n"])), getMediaQuery('medium'));
export var ButtonsWrapper = styled(Flex).attrs(function (props) {
  return {
    props: props
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  ", " {\n    justify-content: end;\n  }\n"])), getMediaQuery('medium'));
export var Wrapper = styled(Flex).attrs(function (props) {
  return {
    props: props
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  line-height: 22px;\n  padding: 25px 20px;\n  width: 100%;\n  flex-direction: column-reverse;\n  ", " {\n    flex-direction: row;\n    justify-content: end;\n  }\n"])), getMediaQuery('medium'));