import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import { Icon } from '@magalu/stereo-ui-icons';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var ArrowIcon = styled(Icon).attrs(function (props) {
  return _extends({
    position: 'relative'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var ExtraInfoFlex = styled(Flex).attrs(function (props) {
  return _extends({}, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var Wrapper = styled(Flex).attrs(function (props) {
  return _extends({
    bg: 'background.white',
    flexDirection: 'column',
    mb: 2,
    pb: 3
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: fit-content;\n  ", " {\n    border-radius: 8px;\n  }\n"])), getMediaQuery('medium'));