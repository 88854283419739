import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import OfferPriceAndInstallments from '../OfferPriceAndInstallments';
import { Checkbox } from './ServiceCard.styles';
var Input = function Input(_ref) {
  var checked = _ref.checked,
    onChange = _ref.onChange,
    offerId = _ref.offerId,
    title = _ref.title,
    selectedOfferPrice = _ref.selectedOfferPrice;
  var handleChange = function handleChange(event) {
    onChange(event.target.checked ? offerId : null);
  };
  return React.createElement(Box, {
    pl: 3,
    pr: 3
  }, React.createElement(Checkbox, {
    checked: checked,
    onChange: handleChange
  }, React.createElement(Text, {
    fontWeight: "bold",
    fontSize: 2
  }, title)), React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "left",
    marginTop: "20px"
  }, React.createElement(OfferPriceAndInstallments, selectedOfferPrice)));
};
var selectedOfferPriceModel = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
process.env.NODE_ENV !== "production" ? Input.propTypes = {
  checked: bool.isRequired,
  offerId: number.isRequired,
  onChange: func.isRequired,
  selectedOfferPrice: selectedOfferPriceModel.isRequired,
  title: string.isRequired
} : void 0;
export default Input;