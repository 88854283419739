import React from 'react';
import { shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
var OfferPriceAndInstallments = function OfferPriceAndInstallments(_ref) {
  var title = _ref.title,
    subtitle = _ref.subtitle;
  return React.createElement(Box, {
    "data-testid": "price-installments-container"
  }, React.createElement(Text, {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 2,
    "data-testid": "title-text"
  }, title.text, React.createElement(Text, {
    fontWeight: "bold",
    as: "span"
  }, title.bold)), React.createElement(Box, {
    height: "10px"
  }), React.createElement(Text, {
    color: "gray",
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 1,
    "data-testid": "subtitle-text"
  }, subtitle));
};
process.env.NODE_ENV !== "production" ? OfferPriceAndInstallments.propTypes = {
  subtitle: string.isRequired,
  title: shape({
    bold: string,
    text: string
  }).isRequired
} : void 0;
OfferPriceAndInstallments.ssr = true;
export default OfferPriceAndInstallments;