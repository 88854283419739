import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var Image = styled(ResponsiveImage)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 100%;\n  height: 79px;\n  width: 79px;\n"])));
export var Wrapper = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    bg: 'background.white',
    color: 'greyisBrown',
    mb: 2,
    mt: 2,
    p: 2
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", " {\n    border-radius: 8px;\n  }\n"])), getMediaQuery('small'));