import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;
import styled from 'styled-components';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Icon } from '@magalu/stereo-ui-icons';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
export var BenefitContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 10,
    marginBottom: 2,
    textAlign: 'left'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var BenefitText = styled(Text).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    color: 'greyish',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    lineHeight: 'display'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var ChevronIcon = styled(Icon).attrs(function (props) {
  return _extends({
    color: 'background.luContentPrimary',
    marginBottom: 0,
    marginLeft: 4
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Container = styled(Flex).attrs(function (props) {
  return _extends({
    gap: 4,
    justifyContent: 'space-between'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  ", " {\n    flex-direction: row;\n  }\n"])), getMediaQuery('medium'));
export var ContractModal = styled(Text).attrs(function (props) {
  return _extends({
    color: 'primary',
    fontSize: 12,
    lineHeight: 'small',
    marginTop: 22
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  text-decoration: underline;\n"])));
export var DescriptionText = styled(Text).attrs(function (props) {
  return _extends({
    color: 'greyishBrown',
    fontSize: 16,
    lineHeight: 'input',
    marginBottom: 3
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export var DialogContainer = styled(Dialog)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-height: 80vh;\n  padding: ", "px ", "px;\n  ", " {\n    width: 85%;\n    padding: ", "px;\n  }\n"])), themeGet('space.3'), themeGet('space.2'), getMediaQuery('medium'), themeGet('space.3'));
export var IconCheck = styled(Icon).attrs(function (props) {
  return _extends({
    color: 'icons.like',
    height: 20,
    marginRight: 9,
    minHeight: 20,
    minWidth: 20,
    width: 20
  }, props);
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral([""])));
export var IframeContainer = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  &::after {\n    padding-top: 56.25%;\n    display: block;\n    content: '';\n  }\n\n  > iframe {\n    position: absolute;\n    padding: 20px 0px 10px;\n    width: 100%;\n    height: 100%;\n  }\n"])));
export var LuContainer = styled(Flex).attrs(function (props) {
  return _extends({
    flexDirection: 'row',
    marginTop: 3,
    width: 250
  }, props);
})(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var LuTitle = styled(Text).attrs(function (props) {
  return _extends({
    color: 'background.luContentPrimary',
    fontWeight: 'light',
    lineHeight: 'display',
    marginLeft: 10
  }, props);
})(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  margin-top: 2px;\n"])));
export var ModalContent = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  line-height: ", ";\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
});
export var ServiceLogo = styled(ResponsiveImage)(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  align-items: 'flex-start';\n  max-width: 170px;\n  margin-bottom: 20px;\n"])));