import { useCallback } from 'react';
import { usePublisher } from '@magalu/mixer-publisher';
import { slug } from '@magalu/mixer-utils';
var usePublishServiceEvents = function usePublishServiceEvents(_ref) {
  var services = _ref.services,
    selectedServicesIds = _ref.selectedServicesIds,
    servicesSelectedOfferId = _ref.servicesSelectedOfferId;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var publishServiceClickEvent = useCallback(function (_ref2) {
    var checked = _ref2.checked,
      service = _ref2.service;
    if ((service == null ? void 0 : service.description) != null) {
      publish('services:click', {
        eventLabel: "".concat(checked ? 'selecionou' : 'desmarcou', ":").concat(slug(service.description)).toLowerCase()
      });
    }
  }, [publish]);
  var publishAddServicesEvent = useCallback(function () {
    var _Object$values;
    if ((services == null ? void 0 : services.length) > 0 && (selectedServicesIds == null ? void 0 : selectedServicesIds.length) > 0 && ((_Object$values = Object.values(servicesSelectedOfferId || {})) == null ? void 0 : _Object$values.length) > 0) {
      var selectedServices = services.filter(function (service) {
        return selectedServicesIds.includes(service.id);
      });
      var offers = selectedServices.map(function (service) {
        return service.offers.find(function (offer) {
          return offer.id === servicesSelectedOfferId[service.id];
        });
      });
      var offersSluggedDescriptions = offers.map(function (offer) {
        return slug(offer.description).toLowerCase();
      }).join('|');
      publish('services:add', {
        offers: offersSluggedDescriptions
      });
    }
  }, [publish, services, selectedServicesIds, servicesSelectedOfferId]);
  var publishKnowMore = useCallback(function (_ref3) {
    var service = _ref3.service;
    publish('services:know-more', {
      service: slug(service).toLowerCase()
    });
  }, [publish]);
  var publishServiceMoreDetails = useCallback(function (_ref4) {
    var service = _ref4.service;
    publish('services:more-details', {
      service: slug(service).toLowerCase()
    });
  }, [publish]);
  var publishServiceOfferChange = useCallback(function (_ref5) {
    var service = _ref5.service,
      newOfferId = _ref5.newOfferId;
    var offerId = servicesSelectedOfferId[service == null ? void 0 : service.id];
    var higher = newOfferId - offerId > 0;
    publish('services:offer-change', {
      higher: higher,
      service: slug(service == null ? void 0 : service.description).toLowerCase()
    });
  }, [publish, servicesSelectedOfferId]);
  var publishServiceVideo = useCallback(function (_ref6) {
    var service = _ref6.service;
    publish('services:video', {
      service: slug(service).toLowerCase()
    });
  }, [publish]);
  var publishServiceLegalInfo = useCallback(function (_ref7) {
    var service = _ref7.service;
    publish('services:legal-info', {
      service: slug(service).toLowerCase()
    });
  }, [publish]);
  var publishRefuseServiceEvent = function publishRefuseServiceEvent() {
    publish('services:refuse');
  };
  return {
    publishAddServicesEvent: publishAddServicesEvent,
    publishKnowMore: publishKnowMore,
    publishRefuseServiceEvent: publishRefuseServiceEvent,
    publishServiceClickEvent: publishServiceClickEvent,
    publishServiceLegalInfo: publishServiceLegalInfo,
    publishServiceMoreDetails: publishServiceMoreDetails,
    publishServiceOfferChange: publishServiceOfferChange,
    publishServiceVideo: publishServiceVideo
  };
};
export default usePublishServiceEvents;