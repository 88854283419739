import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Collapse from '@magalu/stereo-ui/atoms/Collapse';
import Text from '@magalu/stereo-ui/atoms/Text';
import { usePublisher } from '@magalu/mixer-publisher';
import ServiceCard from '../ServiceCard';
import ServiceInfo from '../ServiceInfo';
import { ArrowIcon, ExtraInfoFlex, Wrapper } from './ServiceGroup.styles';
var SEE_MORE_CLICK_EVENT = 'financial-services-separate-sale:seemore:click';
var ServiceGroup = function ServiceGroup(props) {
  var _service$period;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var service = props.service;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var extraInfo = service.extraInfo;
  var handleInfoClick = function handleInfoClick() {
    publish(SEE_MORE_CLICK_EVENT);
    setIsOpen(!isOpen);
  };
  var renderGroupServiceCard = function renderGroupServiceCard(options) {
    return options == null ? void 0 : options.map(function (option) {
      return React.createElement(ServiceCard, _extends({
        key: "period-".concat(option.value)
      }, option));
    });
  };
  return React.createElement(Wrapper, {
    key: service == null ? void 0 : service.key,
    "data-testid": "service-group"
  }, renderGroupServiceCard(service == null ? void 0 : (_service$period = service.period) == null ? void 0 : _service$period.options), extraInfo && Object.keys(extraInfo).length > 0 && React.createElement(Box, null, React.createElement(Collapse, {
    trigger: !isOpen
  }, React.createElement(ServiceInfo, extraInfo)), React.createElement(ExtraInfoFlex, {
    alignItems: "center",
    onClick: handleInfoClick,
    pt: 3,
    pl: 3,
    pr: 3
  }, React.createElement(Text, {
    as: "span",
    fontSize: 1,
    "data-testid": "open-service-info"
  }, "Ver Mais"), React.createElement(ArrowIcon, {
    name: "Chevron".concat(isOpen ? 'Up' : 'Down')
  }))));
};
ServiceGroup.defaultProps = {
  service: {}
};
var benefitPropType = process.env.NODE_ENV !== "production" ? shape({
  __typename: string,
  description: string
}) : {};
var extraInfoPropType = process.env.NODE_ENV !== "production" ? shape({
  agreementText: string,
  benefit: arrayOf(shape({
    description: string
  })),
  label: string,
  labelBold: string,
  title: string,
  videoUrl: string
}) : {};
var selectedOfferPricePropType = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
var periodOptionPropType = process.env.NODE_ENV !== "production" ? shape({
  checked: bool,
  recommended: bool,
  selectedOfferPrice: selectedOfferPricePropType,
  title: string,
  value: number
}) : {};
var periodPropType = process.env.NODE_ENV !== "production" ? shape({
  options: arrayOf(periodOptionPropType),
  value: number
}) : {};
var servicePropType = process.env.NODE_ENV !== "production" ? shape({
  benefit: arrayOf(benefitPropType),
  extraInfo: extraInfoPropType,
  label: string,
  labelBold: string,
  period: periodPropType,
  title: string,
  videoUrl: string
}) : {};
process.env.NODE_ENV !== "production" ? ServiceGroup.propTypes = {
  service: servicePropType
} : void 0;
export default ServiceGroup;