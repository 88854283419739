import { useMemo } from 'react';
var useLegalInfo = function useLegalInfo(_ref) {
  var footerContent = _ref.footerContent,
    publishServiceLegalInfo = _ref.publishServiceLegalInfo;
  return useMemo(function () {
    return footerContent.map(function (value) {
      return {
        btnTxt: 'Informações legais',
        key: value.title,
        modal: {
          content: value.content,
          title: value.title
        },
        onOpen: function onOpen(open) {
          if (open) {
            publishServiceLegalInfo({
              service: value.title
            });
          }
        },
        title: value.title
      };
    });
  }, [footerContent]);
};
export default useLegalInfo;