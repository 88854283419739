import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { Icon } from '@magalu/stereo-ui-icons';
import { parseVideoUrl, slug } from '@magalu/mixer-utils';
import { buildFormattedPriceLabels, buildTitleByDescription } from '../utils';
import { useFinancialService } from '../FinancialServicesProvider';
import FinancialServiceOption from '../FinancialServiceOption';
import { BenefitContainer, BenefitText, ChevronIcon, Container, ContractModal, DialogContainer, DescriptionText, IconCheck, IframeContainer, LuContainer, LuTitle, ModalContent, ServiceLogo } from './FinancialServiceCard.styles';
var DeclineServiceOption = function DeclineServiceOption(_ref) {
  var name = _ref.name,
    code = _ref.code,
    checked = _ref.checked,
    getDeclineOptionByCode = _ref.getDeclineOptionByCode,
    onChange = _ref.onChange;
  var _getDeclineOptionByCo = getDeclineOptionByCode(code),
    title = _getDeclineOptionByCo.title,
    description = _getDeclineOptionByCo.description;
  return React.createElement(FinancialServiceOption, {
    name: name,
    title: title,
    description: description,
    checked: checked,
    onChange: onChange
  });
};
process.env.NODE_ENV !== "production" ? DeclineServiceOption.propTypes = {
  checked: bool.isRequired,
  code: string.isRequired,
  getDeclineOptionByCode: func.isRequired,
  name: string.isRequired,
  onChange: func.isRequired
} : void 0;
var FinancialServiceCard = function FinancialServiceCard(_ref2) {
  var _service$offers;
  var service = _ref2.service,
    quantity = _ref2.installment.quantity,
    staticProps = _ref2["static"];
  var labelBold = staticProps.labelBold,
    label = staticProps.label,
    title = staticProps.title;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    textModalOpen = _useState4[0],
    setTextModalOpen = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    videoModalOpen = _useState6[0],
    setVideoModalOpen = _useState6[1];
  var _React$useState = React.useState(null),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    serviceActive = _React$useState2[0],
    setServiceActive = _React$useState2[1];
  var formattedName = slug(service == null ? void 0 : service.title);
  var _useFinancialService = useFinancialService(),
    updateSelectedService = _useFinancialService.updateSelectedService,
    removeSelectedService = _useFinancialService.removeSelectedService,
    getDeclineOptionByCode = _useFinancialService.getDeclineOptionByCode;
  var handleSelectedService = function handleSelectedService(_ref3) {
    var id = _ref3.id,
      description = _ref3.description,
      price = _ref3.price,
      offerId = _ref3.offerId,
      wageCompensation = _ref3.wageCompensation,
      index = _ref3.index;
    setServiceActive(index);
    updateSelectedService({
      description: description,
      id: id,
      offerId: offerId,
      price: price,
      wageCompensation: wageCompensation
    });
  };
  var logoURL = service.logoURL,
    benefit = service.benefit,
    luContentURL = service.luContentURL;
  var videoUrl = parseVideoUrl(luContentURL);
  var handleClickEvent = useCallback(function (modalType) {
    setOpen(!open);
    if (modalType === 'text') {
      setTextModalOpen(!textModalOpen);
      setVideoModalOpen(false);
      return;
    }
    setVideoModalOpen(!videoModalOpen);
    setTextModalOpen(false);
  }, [textModalOpen, videoModalOpen]);
  return React.createElement(Container, {
    "data-testid": "financia-service-card-container"
  }, React.createElement("div", null, React.createElement(ServiceLogo, {
    "data-testid": "logo-container",
    src: logoURL
  }), React.createElement(DescriptionText, {
    "data-testid": "description-container"
  }, service == null ? void 0 : service.description), React.createElement(BenefitContainer, null, benefit == null ? void 0 : benefit.map(function (_ref4) {
    var description = _ref4.description;
    return React.createElement(BenefitText, {
      key: slug(description),
      "data-testid": "benefit-container"
    }, React.createElement(IconCheck, {
      name: "Done"
    }), description);
  })), React.createElement(ContractModal, {
    onClick: function onClick() {
      return handleClickEvent('text');
    },
    "data-testid": "contract-title"
  }, "".concat(title, " ").concat(service == null ? void 0 : service.title)), !!videoUrl && React.createElement(LuContainer, {
    onClick: function onClick() {
      return handleClickEvent('video');
    },
    "data-testid": "lu-container"
  }, React.createElement(Icon, {
    name: "VideoCircle"
  }), label && React.createElement(LuTitle, null, React.createElement("strong", null, labelBold), label), React.createElement(ChevronIcon, {
    name: "ChevronRight"
  })), React.createElement(DialogContainer, {
    "data-testid": "modal-input",
    onClickOutside: function onClickOutside() {
      return handleClickEvent(textModalOpen ? 'text' : 'video');
    },
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: open,
    showClose: true
  }, textModalOpen && React.createElement(ModalContent, {
    dangerouslySetInnerHTML: {
      __html: service == null ? void 0 : service.agreementText
    },
    "data-testid": "agreement-content"
  }), videoModalOpen && React.createElement(IframeContainer, null, React.createElement("iframe", {
    src: videoUrl,
    title: "YouTube video player",
    frameBorder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true,
    "data-testid": "video-content"
  })))), React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2
  }, Boolean(service == null ? void 0 : (_service$offers = service.offers) == null ? void 0 : _service$offers.length) && (service == null ? void 0 : service.offers.map(function (_ref5, index, _ref6) {
    var description = _ref5.description,
      offerId = _ref5.id,
      price = _ref5.price,
      wageCompensation = _ref5.wageCompensation;
    var length = _ref6.length;
    var formattedTitle = buildTitleByDescription({
      description: description,
      removeString: service == null ? void 0 : service.title
    });
    var _buildFormattedPriceL = buildFormattedPriceLabels({
        price: price,
        quantity: quantity
      }),
      formattedPrice = _buildFormattedPriceL.formattedPrice,
      formattedInstallment = _buildFormattedPriceL.formattedInstallment;
    return React.createElement(React.Fragment, {
      key: slug(description)
    }, React.createElement(FinancialServiceOption, {
      name: formattedName,
      title: formattedTitle,
      description: "".concat(formattedPrice, " (").concat(formattedInstallment, ")"),
      onChange: function onChange() {
        handleSelectedService({
          description: description,
          id: service == null ? void 0 : service.code,
          index: index,
          offerId: offerId,
          price: price,
          wageCompensation: wageCompensation
        });
      },
      checked: serviceActive === index
    }), index + 1 === length && React.createElement(DeclineServiceOption, {
      key: "decline-service",
      name: formattedName,
      code: service == null ? void 0 : service.code,
      checked: serviceActive === null,
      getDeclineOptionByCode: getDeclineOptionByCode,
      onChange: function onChange() {
        setServiceActive(null);
        removeSelectedService({
          id: service == null ? void 0 : service.code
        });
      }
    }));
  }))));
};
process.env.NODE_ENV !== "production" ? FinancialServiceCard.propTypes = {
  installment: shape({
    quantity: number
  }),
  service: shape({
    agreementText: string,
    benefit: arrayOf(shape({
      description: string
    })),
    code: string,
    description: string,
    id: string,
    logoURL: string,
    luContentURL: string,
    offers: arrayOf(shape({
      campaignId: string,
      description: string,
      id: number,
      price: number,
      treeId: string,
      wageCompensation: shape({
        amount: string,
        percentage: string
      })
    })),
    title: string
  }),
  "static": shape({
    label: string,
    labelBold: string,
    title: string
  })
} : void 0;
FinancialServiceCard.defaultProps = {
  installment: {},
  service: {},
  "static": {
    label: '',
    labelBold: '',
    title: ''
  }
};
export default FinancialServiceCard;