import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import Input from './Input';
import Recommended from './Recommended';
import { HorizontalRule, Wrapper } from './ServiceCard.styles';
var ServiceCard = function ServiceCard(props) {
  var title = props.title,
    checked = props.checked,
    onChange = props.onChange,
    value = props.value,
    recommended = props.recommended,
    selectedOfferPrice = props.selectedOfferPrice;
  return React.createElement(Wrapper, {
    "data-testid": "service-card"
  }, React.createElement(Recommended, {
    recommended: recommended
  }), React.createElement(Input, {
    checked: checked,
    onChange: onChange,
    title: title,
    offerId: value,
    selectedOfferPrice: selectedOfferPrice
  }), React.createElement(HorizontalRule, {
    size: 2
  }));
};
ServiceCard.defaultProps = {
  selectedOfferPrice: {}
};
var selectedOfferPriceModel = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
var propTypesModel = process.env.NODE_ENV !== "production" ? {
  checked: bool.isRequired,
  onChange: func.isRequired,
  recommended: bool.isRequired,
  selectedOfferPrice: selectedOfferPriceModel,
  title: string.isRequired,
  value: number.isRequired
} : {};
process.env.NODE_ENV !== "production" ? ServiceCard.propTypes = propTypesModel : void 0;
export default ServiceCard;