import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { node, shape } from 'prop-types';
import { DEFAULT_DECLINE_MESSAGE } from './constants';
var Context = createContext(undefined);
var FinancialServiceProvider = function FinancialServiceProvider(_ref) {
  var children = _ref.children,
    overrideDeclineMessages = _ref.value.overrideDeclineMessages;
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    selectedServices = _useState2[0],
    setSelectedServices = _useState2[1];
  var _useState3 = useState(_extends({}, DEFAULT_DECLINE_MESSAGE, overrideDeclineMessages)),
    _useState4 = _slicedToArray(_useState3, 1),
    declineMessages = _useState4[0];

  var updateSelectedService = useCallback(function (_ref2) {
    var id = _ref2.id,
      description = _ref2.description,
      price = _ref2.price,
      wageCompensation = _ref2.wageCompensation,
      offerId = _ref2.offerId;
    setSelectedServices(function (prev) {
      return _extends({}, prev, _defineProperty({}, id, {
        description: description,
        id: offerId,
        price: price,
        wageCompensation: wageCompensation
      }));
    });
  }, []);
  var removeSelectedService = useCallback(function (_ref3) {
    var id = _ref3.id;
    setSelectedServices(function (prev) {
      var cloneState = _extends({}, prev);
      delete cloneState[id];
      return _extends({}, cloneState);
    });
  }, []);

  var getDeclineOptionByCode = function getDeclineOptionByCode(code) {
    return declineMessages[code] || (declineMessages == null ? void 0 : declineMessages["default"]);
  };
  var contextValue = useMemo(function () {
    return {
      getDeclineOptionByCode: getDeclineOptionByCode,
      removeSelectedService: removeSelectedService,
      selectedServices: selectedServices,
      updateSelectedService: updateSelectedService
    };
  }, [getDeclineOptionByCode, removeSelectedService, selectedServices, updateSelectedService]);
  return React.createElement(Context.Provider, {
    value: contextValue
  }, children);
};
var useFinancialService = function useFinancialService() {
  var context = useContext(Context);
  if (context === undefined) {
    throw new Error('useFinancialService can only be used inside FinancialServiceProvider');
  }
  return context;
};
process.env.NODE_ENV !== "production" ? FinancialServiceProvider.propTypes = {
  children: node.isRequired,
  value: shape({
    overrideDeclineMessages: shape({})
  })
} : void 0;
FinancialServiceProvider.defaultProps = {
  value: {}
};
export { FinancialServiceProvider, useFinancialService };