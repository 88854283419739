import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import HorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import { arrayOf, shape, string } from 'prop-types';
import Benefits from './Benefits';
import Dialog from './Dialog';
import { ArrowIcon, AgreementLabel, BenefitContainer, RuleWithMargin } from './ServiceInfo.styles';
import useServiceInfo from './useServiceInfo';
import VideoButton from './VideoButton';
var ServiceInfo = function ServiceInfo(props) {
  var _useServiceInfo = useServiceInfo(props),
    agreement = _useServiceInfo.agreement,
    benefit = _useServiceInfo.benefit,
    dialog = _useServiceInfo.dialog,
    handleClickEvent = _useServiceInfo.handleClickEvent,
    video = _useServiceInfo.video;
  return React.createElement(React.Fragment, null, React.createElement(BenefitContainer, {
    mb: 2,
    mt: 3
  }, React.createElement(Benefits, {
    benefit: benefit
  })), video.label && React.createElement(React.Fragment, null, React.createElement(RuleWithMargin, null), React.createElement(VideoButton, _extends({
    handleClickEvent: handleClickEvent
  }, video))), agreement.title && React.createElement(React.Fragment, null, React.createElement(RuleWithMargin, null), React.createElement(Flex, {
    alignItems: "center",
    pr: 3
  }, React.createElement(AgreementLabel, {
    onClick: function onClick() {
      return handleClickEvent('agreement');
    },
    "data-testid": "agreement-trigger",
    mb: 3,
    mt: 3
  }, agreement.title), React.createElement(ArrowIcon, {
    name: "ChevronRight"
  }))), React.createElement(HorizontalRule, null), React.createElement(Dialog, _extends({
    handleClickEvent: handleClickEvent
  }, dialog)));
};
ServiceInfo.defaultProps = {
  agreementText: '',
  benefit: [{
    description: ''
  }],
  label: '',
  labelBold: '',
  title: '',
  videoUrl: ''
};
process.env.NODE_ENV !== "production" ? ServiceInfo.propTypes = {
  agreementText: string,
  benefit: arrayOf(shape({
    description: string
  })),
  label: string,
  labelBold: string,
  title: string,
  videoUrl: string
} : void 0;
ServiceInfo.ssr = true;
export default ServiceInfo;