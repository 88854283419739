import React from 'react';
import { bool, func, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import Radio from '@magalu/stereo-ui/atoms/Radio';
import { Container } from './FinancialServiceOption.styles';
var FinancialServiceOption = function FinancialServiceOption(_ref) {
  var name = _ref.name,
    title = _ref.title,
    description = _ref.description,
    checked = _ref.checked,
    onChange = _ref.onChange;
  return !!description && React.createElement(Container, {
    "data-testid": "financial-option"
  }, React.createElement("div", null, React.createElement(Radio, {
    value: "radio",
    name: name,
    checked: checked,
    onChange: onChange
  })), React.createElement(Flex, {
    flexDirection: "column",
    gap: 2
  }, !!title && React.createElement(Text, {
    fontWeight: "bold",
    lineHeight: "fontSize",
    "data-testid": "financial-option-title"
  }, title), !!description && React.createElement(Text, {
    fontWeight: "regular",
    lineHeight: !title && !!description ? 'display' : 'fontSize',
    "data-testid": "financial-option-description"
  }, description)));
};
process.env.NODE_ENV !== "production" ? FinancialServiceOption.propTypes = {
  checked: bool,
  description: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  title: string
} : void 0;
FinancialServiceOption.defaultProps = {
  checked: false,
  title: ''
};
export default FinancialServiceOption;