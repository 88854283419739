import React from 'react';
import Box from '@magalu/stereo-ui/atoms/Box';
import { func, string, shape } from 'prop-types';
import { usePublisher } from '@magalu/mixer-publisher';
import AuthorizationTerm from '../AuthorizationTerm';
import { Button, ButtonsWrapper, Wrapper } from './ActionButtons.styles';
var PAYMENT_CLICK_EVENT = 'financial-services-separate-sale:payment:click';
var ActionButtons = function ActionButtons(_ref) {
  var authorizationTermText = _ref.authorizationTermText,
    handleAcquireServices = _ref.handleAcquireServices;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleOnClick = function handleOnClick() {
    publish(PAYMENT_CLICK_EVENT);
    handleAcquireServices.continueAddingServices();
  };
  return React.createElement(Wrapper, {
    "data-testid": "buttons-wrapper"
  }, React.createElement(AuthorizationTerm, {
    authorizationTermText: authorizationTermText
  }), React.createElement(ButtonsWrapper, null, React.createElement(Button, {
    disabled: handleAcquireServices.disableContinueAddingServices,
    color: "secondary",
    uppercase: false,
    onClick: handleOnClick,
    "data-testid": "acquire-services"
  }, "Ir para pagamento")), React.createElement(Box, {
    height: "20px"
  }));
};
ActionButtons.defaultProps = {
  authorizationTermText: 'Termos de autorização'
};
process.env.NODE_ENV !== "production" ? ActionButtons.propTypes = {
  authorizationTermText: string,
  handleAcquireServices: shape({
    continueAddingServices: func.isRequired,
    continueWithoutServices: func.isRequired
  }).isRequired
} : void 0;
ActionButtons.ssr = true;
export default ActionButtons;