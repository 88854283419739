import React from 'react';
import Text from '@magalu/stereo-ui/atoms/Text';
import { shape, string } from 'prop-types';
import { Image, Wrapper } from './ProductCard.styles';
var ProductCard = function ProductCard(props) {
  var productInfo = props.productInfo;
  return React.createElement(Wrapper, {
    "data-testid": "product-service"
  }, React.createElement(Image, {
    src: productInfo.src,
    alt: productInfo.alt
  }), React.createElement(Text, null, productInfo.title));
};
ProductCard.defaultProps = {
  productInfo: {
    alt: '',
    src: '',
    title: ''
  }
};
var propTypesModel = process.env.NODE_ENV !== "production" ? {
  productInfo: shape({
    alt: string,
    src: string,
    title: string
  })
} : {};
process.env.NODE_ENV !== "production" ? ProductCard.propTypes = propTypesModel : void 0;
export default ProductCard;