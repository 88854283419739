import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
export var ClickableText = styled(Text)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n  text-decoration: underline;\n"])));
export var DialogContainer = styled(Dialog)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-height: 80vh;\n  padding: ", "px ", "px;\n  ", " {\n    width: 85%;\n    padding: ", "px;\n  }\n"])), themeGet('space.3'), themeGet('space.2'), getMediaQuery('medium'), themeGet('space.3'));
export var DialogContent = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  line-height: ", ";\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
});
export var MainText = styled(Text)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  text-align: justify;\n"])), themeGet('fontSizes.0'));
export var Wrapper = styled(Flex).attrs(function (props) {
  return _extends({}, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  justify-content: center;\n"])));