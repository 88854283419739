import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';

var calculateInstallments = function calculateInstallments(value) {
  if (value / 10 >= 30) {
    return 10;
  }
  for (var index = 9; index >= 1; index -= 1) {
    if (value / index >= 30) {
      return index;
    }
  }
  return 1;
};
var getOfferPriceAndInstallments = function getOfferPriceAndInstallments() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var title = {
    bold: '',
    text: ''
  };

  var subtitle = '';
  try {
    var installments = input.installments,
      offer = input.offer;
    var newInstallments = !installments || installments <= 0 ? calculateInstallments(offer.price) : installments;
    title.text = "".concat(newInstallments, "x de ");
    title.bold = "".concat(currencyFormatter({
      value: (offer.price / newInstallments).toFixed(2)
    }), " sem juros");
    subtitle = "".concat(currencyFormatter({
      value: offer.price
    }), " no pix");
  } catch (_unused) {
    title.text = '';
    title.bold = '';
    subtitle = '';
  }
  return {
    subtitle: subtitle,
    title: title
  };
};
export default getOfferPriceAndInstallments;