import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var Container = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    borderColor: 'whisper',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    forwardedAs: 'label',
    gap: 3,
    paddingX: 16
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  min-width: 100%;\n  min-height: 80px;\n  ", " {\n    max-width: 300px;\n    min-width: 300px;\n  }\n"])), getMediaQuery('medium'));