import _extends from "@babel/runtime/helpers/esm/extends";
import getOffersInput from './getOffersInput';
var getServiceInfos = function getServiceInfos(props) {
  var service = props.service,
    servicesSelectedOfferId = props.servicesSelectedOfferId,
    installments = props.installments,
    onServicesSelectedOfferChange = props.onServicesSelectedOfferChange;
  return {
    extraInfo: _extends({}, service.extraInfo, {
      onModalOpen: function onModalOpen() {}
    }),
    key: service.id,
    period: getOffersInput({
      installments: installments,
      onServicesSelectedOfferChange: onServicesSelectedOfferChange,
      service: service,
      serviceIdToOfferId: servicesSelectedOfferId
    })
  };
};
export default getServiceInfos;