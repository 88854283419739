import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
var useSelectedServices = function useSelectedServices(defaultId) {
  var defaultIds = defaultId !== null ? [defaultId] : [];
  var _useState = useState(defaultIds),
    _useState2 = _slicedToArray(_useState, 2),
    selectedServicesIds = _useState2[0],
    setSelectedServicesIds = _useState2[1];
  var onSelectedServicesIdsChanged = function onSelectedServicesIdsChanged(changedId, checked) {
    setSelectedServicesIds(function (prevState) {
      return checked ? [].concat(_toConsumableArray(prevState), [changedId]) : prevState.filter(function (id) {
        return id !== changedId;
      });
    });
  };
  return {
    onSelectedServicesIdsChanged: onSelectedServicesIdsChanged,
    selectedServicesIds: selectedServicesIds
  };
};
export default useSelectedServices;